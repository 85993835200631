<template>
  <el-container>
    <el-aside width="200px">
      <el-row class="tac">
        <el-col>
          <el-menu class="el-menu-demo">
            <el-menu-item index="0" @click="handleSelect()"
              >全部展馆</el-menu-item
            >
            <el-menu-item
              v-for="(item) in parents"
              :key="item.id"
              :index="item.id.toString()"
              @click="handleSelect(item)"
            >
              <i class="el-icon-document"></i>
              <span slot="title" v-text="item.name"></span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </el-aside>
    <el-container>
      <div style="margin-left: 5%">
        <Child ref="child" @fatherSearch="search"></Child>
      </div>
    </el-container>
  </el-container>
</template>
<script>
import { listByParent } from "@/api/cms/booth.js";
import Child from "./Child.vue";

export default {
  data() {
    return {
      parents: [],
    };
  },
  components: {
    Child,
  },
  methods: {
    handleSelect(item) {
      if (item == null) item = { id: 0 };
      this.$refs.child.search(item);
    },
    search() {
      listByParent(0).then((res) => {
        this.parents = res;
      });
    },
  },
  created() {
    this.search();
  },
};
</script>
<style></style>